import BaseAPI from "../BaseAPI";

class Information extends BaseAPI {
  constructor(props) {
    super(props);

    this.baseUrl = "/information";
  }

  /**
   * Gets an info for the page "Legal information"
   *
   * @returns {Promise<*|Error>}
   */
  fetchLegalInfo() {
    const url = `${this.baseUrl}/legal-info`;
    const data = {
      // 1: "processingOfPersonalData",
      2: "termsAndConditions",
      3: "cookiePolicy",
      6: "xXtraCard"
    };
    const dataHRV = {
      1: "termsAndConditions",
      4: "cookiePolicy",
      3: "processingOfPersonalData"
    };
    const enums = process.env.REACT_APP_COUNTRY === "hrv" ? dataHRV : data;
    return this.get({ url }).then(res =>
      res.map(info => ({
        ...info,
        type: enums[info.docType]
      }))
    );
  }

  /**
   * Gets an info for the page Impressum
   *
   * @returns {Promise<*|Error>}
   */
  fetchImpressum() {
    const url = `${this.baseUrl}/impressum`;
    const version = "";
    const get = this.withHTML(this.get);

    return get({ url, version });
  }

  /**
   * Gets an info for the page B2B
   *
   * @returns {Promise<*|Error>}
   */
  fetchB2b() {
    const url = `${this.baseUrl}/b2b`;
    const version = "";
    const get = this.withHTML(this.get);

    return get({ url, version });
  }

  /**
   * Gets an info for the bonus card
   *
   * @returns {Promise<*|Error>}
   */
  fetchBonusCardInfo() {
    const url = `${this.baseUrl}/bonus-card`;
    const version = "";
    const get = this.withHTML(this.get);

    return get({ url, version });
  }

  /**
   * Gets an info for the family film club
   *
   * @returns {Promise<*|Error>}
   */
  fetchFamilyFilmClub() {
    const url = `${this.baseUrl}/familyfilmclub`;
    const version = "";
    const get = this.withHTML(this.get);

    return get({ url, version });
  }

  /**
   * Gets an info for the birthday room
   *
   * @returns {Promise<*|Error>}
   */
  fetchBirthdayRoom() {
    const url = `${this.baseUrl}/birthdayroom`;
    const version = "";
    const get = this.withHTML(this.get);

    return get({ url, version });
  }
}

export default Information;
